<template>
	<v-flex v-if="simpleButtonDisplay && !listTileDisplay && !singleTileDisplay" shrink>
		<w-btn
			v-if="canValidate"
			:flat="flat"
			:icon="hasMany ? 'mdi-checkbox-multiple-marked-circle' : 'mdi-checkbox-marked-circle'"
			:loading="validationLoading"
			mini
			@click="validate()"
		>
			{{ $tc('documents.validate_documents', value.length) }}
		</w-btn>
		<w-btn
			v-if="canInvalidate"
			:flat="flat"
			:icon="hasMany ? 'mdi-checkbox-multiple-marked-circle-outline' : 'mdi-checkbox-marked-circle-outline'"
			:loading="invalidationLoading"
			mini
			@click="invalidate()"
		>
			{{ $tc('documents.unvalidate_documents', value.length) }}
		</w-btn>
	</v-flex>
	<v-layout v-else-if="listTileDisplay && !singleTileDisplay" column>
		<v-list-tile v-if="canValidate" avatar @click="validate()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="validationLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">{{ hasMany ? 'mdi-checkbox-multiple-marked-circle' : 'mdi-checkbox-marked-circle' }}</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ $tc('documents.validate_documents', value.length) }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile v-if="canInvalidate" avatar @click="invalidate()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="invalidationLoading" color="primary" indeterminate />
				<v-icon v-else color="primary"> {{ hasMany ? 'mdi-checkbox-multiple-marked-circle-outline' : 'mdi-checkbox-marked-circle-outline' }}</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ $tc('documents.unvalidate_documents', value.length) }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
	</v-layout>
	<v-list-tile v-else-if="singleTileDisplay && hasOne && (canValidate || canInvalidate)">
		<v-list-tile-content>
			<v-list-tile-title><v-flex v-t="'documents.validation'" /></v-list-tile-title>
			<v-list-tile-sub-title v-if="isValidated">
				{{ $t('documents.validated_at', { date: validationDate }) }}
				<br />
				<span v-if="validator">
					{{ $t('documents.validated_by', { user: validator.username }) }}
				</span>
			</v-list-tile-sub-title>
			<v-list-tile-sub-title v-else>{{ $t('documents.document_to_validate') }}</v-list-tile-sub-title>
		</v-list-tile-content>
		<v-list-tile-action>
			<w-switch
				:value="isValidated"
				:loading="invalidationLoading || validationLoading"
				@input="toggleValidation"
				@keyup.enter="toggleValidationWithEnter"
			/>
		</v-list-tile-action>
	</v-list-tile>
</template>

<script>
import { mapState } from 'vuex'

import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentVersionActionModuleGuard.js'

export default {
	name: 'ValidationButtons',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		flat: {
			type: Boolean,
			required: false,
			default: true
		},
		singleTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		withValidator: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			invalidationLoading: false,
			validationLoading: false,
			validator: null
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return !Array.isArray(this.value) && this.value ? [this.value] : this.value
		},
		node: function () {
			return !Array.isArray(this.value) ? this.value : this.value[0]
		},
		canInvalidate: function () {
			return (
				this.nodes &&
				this.isAccountant &&
				this.nodes.every(node => node.is_document && !node.is_deleted) &&
				this.nodes.some(node => node.latestVersion?.validated_at != null)
			)
		},
		canValidate: function () {
			return (
				this.nodes &&
				this.isAccountant &&
				this.nodes.every(node => node.is_document && !node.is_deleted) &&
				this.nodes.some(node => node.latestVersion?.validated_at == null)
			)
		},
		hasOne: function () {
			return this.nodes && this.nodes.length == 1
		},
		hasMany: function () {
			return this.nodes && this.nodes.length > 1
		},
		currentVersion: function () {
			let result = null
			if (this.hasOne && this.node?.latestVersion) {
				result = this.node.latestVersion
			}
			return result
		},
		isValidated: function () {
			return this.nodes && this.nodes.every(node => node?.latestVersion?.validated_at != null)
		},
		validationDate: function () {
			return this.isValidated && this.hasOne ? this.$translateDateTime(this.node?.latestVersion?.validated_at, 'short') : null
		}
	},
	watch: {
		node: {
			handler: function () {
				this.loadValidator()
			}
		}
	},
	methods: {
		toggleValidation: function (validate) {
			if (validate) {
				this.validate()
			} else {
				this.invalidate()
			}
			this.loadValidator()
		},
		toggleValidationWithEnter: function (event) {
			this.toggleValidation(!event.target.checked)
		},
		validate: function () {
			this.validationLoading = true
			return this.updateValidation(true, this.$tc('documents.messages.document_validated', this.nodes.length)).finally(() => {
				this.loadValidator()
				this.validationLoading = false
			})
		},
		invalidate: function () {
			this.invalidationLoading = true
			return this.updateValidation(false, this.$tc('documents.messages.document_invalidated', this.nodes.length)).finally(() => {
				this.loadValidator()
				this.invalidationLoading = false
			})
		},
		updateValidation: function (validate, message) {
            return Promise.all(this.nodes.filter(node => !node.latestVersion).map(node => this.service.getDocumentVersions(this.vendorId, node)))
                .then(() => {
                    return Promise.all(
                        this.nodes
                            .filter(node => node.isValidated == !validate)
                            .map(node => {
                                this.service.updateDocumentVersion(this.vendorId, node.id, node.latestVersion, null, { validated_at: validate ? Date.now() : null })
                            })
                    )
                })
                .then(() => this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message))
        },
		loadValidator: function () {
			if (this.isValidated && this.hasOne && this.withValidator) {
				this.service.getDocumentVersionValidator(this.vendorId, this.node.id, this.currentVersion).then(documentVersionValidator => {
					this.validator = documentVersionValidator
				})
			}
		}
	}
}
</script>
