// ============================================================================
// DocumentVersionsService
// -----------------------
// Document version services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

import API from '@/apis/DocumentVersionsApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			document: {
				version: {
					create: function (vendorId, documentId, data, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, data: data })
						return API.vendor.document.version.create(vendorId, documentId, data, params)
					},
					read: function (vendorId, documentId, versionId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
						return API.vendor.document.version.read(vendorId, documentId, versionId, params)
					},
					update: function (vendorId, nodeId, versionId, params, data) {
						RequiredArguments({ vendorId: vendorId, nodeId: nodeId, versionId: versionId, data: data })
						return API.vendor.document.version.update(vendorId, nodeId, versionId, params, data)
					},
					delete: function (vendorId, documentId, versionId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
						return API.vendor.document.version.delete(vendorId, documentId, versionId, params)
					},
					content: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return API.vendor.document.version.content(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					author: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return API.vendor.document.version.author.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					validator: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
							return API.vendor.document.version.validator.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					checksum: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
							return API.vendor.document.version.checksum.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				},
				versions: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId })
						return API.vendor.document.versions.read(vendorId, documentId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	service: {
		vendor: {
			document: {
				version: {
					create: function (vendorId, documentId, data, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, data: data })
						return Private.requests.vendor.document.version.create(vendorId, documentId, data, params).then(createdVersion => {
							return Private.store.vendor.document.version.addOrUpdate(createdVersion).then(() => {
								let result = Private.store.vendor.document.version.get(createdVersion)
								if (!result) {
									result = createdVersion
								}
								return result
							})
						})
					},
					read: function (vendorId, documentId, version, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
						return Private.requests.vendor.document.version.read(vendorId, documentId, version.id, params).then(foundVersion => {
							return Private.store.vendor.document.version.addOrUpdate(foundVersion).then(() => {
								let result = Private.store.vendor.document.version.get(foundVersion)
								if (!result) {
									result = foundVersion
								}
								return result
							})
						})
					},
					load: function (vendorId, documentId, version, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
						return Private.service.vendor.document.version.read(vendorId, documentId, version, params)
					},
					update: function (vendorId, documentId, version, params, data) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version, data: data })
						return Private.requests.vendor.document.version.update(vendorId, documentId, version.id, params, data).then(updatedVersion => {
							return Private.store.vendor.document.version.addOrUpdate(updatedVersion).then(() => {
								let result = Private.store.vendor.document.version.get(updatedVersion)
								if (!result) {
									result = updatedVersion
								}
								return result
							})
						})
					},
					delete: function (vendorId, documentId, version, params = null) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
						return Private.requests.vendor.document.version.delete(vendorId, documentId, version.id, params)
					},
					content: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, version, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
							return Private.requests.vendor.document.version.content.read(vendorId, documentId, version.id, params).then(download => {
								return download
							})
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					author: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, version, params = null) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
							return Private.requests.vendor.document.version.author.read(vendorId, documentId, version.id, params).then(documentVersionAuthor => {
								return Private.store.vendor.document.version.author
									.set({
										documentId: documentId,
										versionId: version.id,
										author: documentVersionAuthor
									})
									.then(() => {
										return documentVersionAuthor
									})
							})
						},
						load: function (vendorId, documentId, version, params) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
							return Private.service.vendor.document.version.author.read(vendorId, documentId, version, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					validator: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, version, params, data) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
							return Private.requests.vendor.document.version.validator.read(vendorId, documentId, version.id, params, data).then(documentVersionValidator => {
								return Private.store.vendor.document.version.validator
									.set({
										documentId: documentId,
										versionId: version.id,
										validator: documentVersionValidator
									})
									.then(() => {
										return documentVersionValidator
									})
							})
						},
						load: function (vendorId, documentId, version, params, data) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, version: version })
							return Private.service.vendor.document.version.validator.read(vendorId, documentId, version, params, data)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					},
					checksum: {
						create: NotImplementedYet,
						read: function (vendorId, documentId, versionId, params) {
							RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
							return Private.requests.vendor.document.version.checksum.read(vendorId, documentId, versionId, params)
						},
						update: NotImplementedYet,
						delete: NotImplementedYet
					}
				},
				versions: {
					create: NotImplementedYet,
					read: function (vendorId, document, parameters = {}) {
						RequiredArguments({ vendorId: vendorId, document: document })
						if (document.deleted_at) {
							if (!parameters.hasOwnProperty('filters')) {
								parameters.filters = {}
							}
							parameters.filters.with_trashed = true
						}
						return Private.requests.vendor.document.versions.read(vendorId, document.id, parameters).then(versions => {
							let result = Promise.resolve(versions)
							if (versions) {
								result = Promise.all(versions.map(version => Private.store.vendor.document.version.addOrUpdate(version))).then(() => {
									const updatedDocument = Private.store.vendor.node.get(document.id)
									let subresult
									if (updatedDocument && updatedDocument.versions) {
										subresult = updatedDocument.versions
									} else {
										subresult = versions
									}
									return subresult
								})
							}
							return result
						})
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	store: {
		vendor: {
			document: {
				version: {
					get: function (version) {
						RequiredArguments({ version: version })
						return store.getters['documents/getDocumentVersion'](version)
					},
					addOrUpdate: function (version) {
						RequiredArguments({ version: version })
						return store.dispatch('documents/addOrUpdateDocumentVersion', version)
					},
					remove: function (version) {
						RequiredArguments({ version: version })
					},
					author: {
						set: function (versionAuthor) {
							RequiredArguments({ versionAuthor: versionAuthor })
							return store.dispatch('documents/setDocumentVersionAuthor', versionAuthor)
						}
					},
					validator: {
						set: function (versionValidator) {
							RequiredArguments({ versionAuthor: versionValidator })
							return store.dispatch('documents/setDocumentVersionValidator', versionValidator)
						}
					},
					signature: {
						addOrUpdate: function (signature) {
							RequiredArguments({ signature: signature })
							return store.dispatch('documents/addOrUpdateDocumentVersionSignature', signature)
						}
					}
				}
			},
			node: {
				get: function (nodeIdentifier) {
					RequiredArguments({ nodeIdentifier: nodeIdentifier })
					return store.getters['documents/findNodeInStructure'](nodeIdentifier)
				}
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	createDocumentVersion: Private.service.vendor.document.version.create,
	getDocumentVersion: Private.service.vendor.document.version.read,
	loadDocumentVersion: Private.service.vendor.document.version.load,
	updateDocumentVersion: Private.service.vendor.document.version.update,
	deleteDocumentVersion: Private.service.vendor.document.version.delete,

	createDocumentVersionAuthor: Private.service.vendor.document.version.author.create,
	getDocumentVersionAuthor: Private.service.vendor.document.version.author.read,
	updateDocumentVersionAuthor: Private.service.vendor.document.version.author.update,
	deleteDocumentVersionAuthor: Private.service.vendor.document.version.author.delete,

	createDocumentVersionValidator: Private.service.vendor.document.version.validator.create,
	getDocumentVersionValidator: Private.service.vendor.document.version.validator.read,
	updateDocumentVersionValidator: Private.service.vendor.document.version.validator.update,
	deleteDocumentVersionValidator: Private.service.vendor.document.version.validator.delete,

	createDocumentVersions: Private.service.vendor.document.versions.create,
	getDocumentVersions: Private.service.vendor.document.versions.read,
	updateDocumentVersions: Private.service.vendor.document.versions.update,
	deleteDocumentVersions: Private.service.vendor.document.versions.delete,

	createDocumentVersionChecksum: Private.service.vendor.document.version.checksum.create,
	getDocumentVersionChecksum: Private.service.vendor.document.version.checksum.read,
	updateDocumentVersionChecksum: Private.service.vendor.document.version.checksum.update,
	deleteDocumentVersionChecksum: Private.service.vendor.document.version.checksum.delete,

	addOrUpdateDocumentVersion: Private.store.vendor.document.version.addOrUpdate,
	addOrUpdateDocumentVersionSignature: Private.store.vendor.document.version.signature.addOrUpdate
}
