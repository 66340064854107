// ============================================================================
// DocumentVersionsApi
// -------------------
// Document version api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, ForbiddenInteraction, NotImplementedYet } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
	vendor: {
		document: {
			version: {
				create: function (vendorId, documentId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId, data: data })
					return Backend.CHUNKUPLOAD(
						URLS.api.vendors.documents.versions.uri,
						[vendorId, documentId],
						params,
						data,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, nodeId: documentId, versionId: versionId })
					return Backend.GET(
						URLS.api.vendors.documents.versions.uri,
						[vendorId, documentId, versionId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				update: function (vendorId, documentId, versionId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, nodeId: documentId, versionId: versionId, data: data })
					return Backend.PATCH(
						URLS.api.vendors.documents.versions.uri,
						[vendorId, documentId, versionId],
						params,
						data,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				delete: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId, versionId: versionId })
					return Backend.DELETE(
						URLS.api.vendors.documents.versions.uri,
						[vendorId, documentId, versionId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				},
				content: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						return Backend.GET(
							URLS.api.vendors.documents.versions.content.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				author: {
					create: ForbiddenInteraction,
					read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ vendorId: vendorId, nodeId: documentId, versionId: versionId })
						return Backend.GET(
							URLS.api.vendors.documents.versions.author.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: ForbiddenInteraction,
					delete: ForbiddenInteraction
				},
				validator: {
					create: ForbiddenInteraction,
					read: function (vendorId, documentId, versionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ vendorId: vendorId, nodeId: documentId, versionId: versionId })
						return Backend.GET(
							URLS.api.vendors.documents.versions.validator.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: ForbiddenInteraction,
					delete: ForbiddenInteraction
				},
				checksum: {
					create: NotImplementedYet,
					read: function (vendorId, documentId, versionId, params, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ vendorId: vendorId, documentId: documentId, params: params })
						return Backend.GET(
							URLS.api.vendors.documents.versions.checksumV2.uri,
							[vendorId, documentId, versionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			versions: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return Backend.GET(URLS.api.vendors.documents.versions.uri, [vendorId, documentId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	}
}
